.footerWrapper{padding: 45px 0px; width: 100%;}
.footerWrapper .footerBoxes .box{background: #dddbd1; padding: 25px 25px; border-radius: 15px; } 
.footerWrapper .footerBoxes .box .info{padding-left: 25px;}
.footerWrapper .footerBoxes .box  img{position: relative; transition: all 0.2s ease-in-out;}
.footerWrapper .footerBoxes .box .info h4{font-size: 25px; font-weight: 600;}
.footerWrapper .footerBoxes .box .info p{margin-bottom: 0px; font-size: 20px; opacity: 0.8; }
.footerWrapper .footerBoxes .box:hover img{transform: translateY(-5px);}
@media (max-width: 992px) {
    .footerWrapper {
        padding: 20px 0;
        width: auto;
    }
.footerBoxes{
    width: auto;
}
    .footerWrapper .footerBoxes .box {
        padding: 15px 15px;
        border-radius: 10px;
        width: auto;
    }
    .footerWrapper .footerBoxes .box img{
        position: relative; width: auto;
        transition: all 0.2s ease-in-out;}
    .footerWrapper .footerBoxes .box .info {
        padding-left: 15px;
    }

    .footerWrapper .footerBoxes .box .info h4 {
        font-size: 20px;
    }

    .footerWrapper .footerBoxes .box .info p {
        font-size: 16px;
    }
}

footer p, footer a{font-size: 18px;}

footer{padding: 60px 0px; padding-bottom: 0px;}

footer .part1 svg{color: #cfb148  !important;}

footer  h3{color: #cfb148; font-weight: 600; margin-bottom: 20px;}
footer .part2 ul li{list-style: none; margin-bottom: 13px;}
footer .part2 ul li a{color:#000; opacity: 0.8; font-size: 500; transition: all 0.2s ease-in-out;}
footer .part2 ul li a:hover{text-decoration: none;color: #cfb148  !important; opacity: 1; padding-left: 5px;}

footer .lastStrip{padding: 15px 0px;}

.phNo svg{ font-size: 45px !important; opacity: 0.8;}
.phNo h3{ font-size:32px !important; color: #cfb148; line-height: 35px !important;}
.phNo p{ font-size: 16px !important; opacity: 0.8;}

footer .lastStrip .part3 h5{margin-bottom: 0px;}
footer .lastStrip .part3 ul{margin-bottom: 0px; padding-left: 25px;}
footer .lastStrip .part3 ul li a{display: flex; align-items: center; justify-content: center; width: 40px; height: 40px; background: #cfb148; border-radius: 50%; transition: all 0.2s ease-in-out !important;}
footer .lastStrip .part3 ul li a svg{color: #fff !important;}
footer .lastStrip .part3 ul li a:hover{background:rgb(51, 51, 51);}

@media screen and (max-width: 768px){
    footer .part2 ul li a{font-size: 16px;}
    footer .lastStrip .part3 ul li a{width: 35px; height: 35px;}
    footer .lastStrip .part3 ul li a svg{font-size: 20px;}
    .phNo h3{ font-size:25px !important; line-height: 30px !important;}
    .phNo p{ font-size: 14px !important;}
}
@media screen and (max-width: 576px){
    footer .part2 ul li a{font-size: 14px;}
    footer .lastStrip .part3 ul li a{width: 30px; height: 30px;}
    footer .lastStrip .part3 ul li a svg{font-size: 18px;}
    .phNo h3{ font-size:20px !important; line-height: 25px !important;}
    .phNo p{ font-size: 12px !important;}
}
@media screen and (max-width: 480px){
    footer .part2 ul li a{font-size: 14px;}
    footer .lastStrip .part3 ul li a{width: 30px; height: 30px;}
    footer .lastStrip .part3 ul li a svg{font-size: 18px;}
    .phNo h3{ font-size:20px !important; line-height: 25px !important;}
    .phNo p{ font-size: 12px !important;}
}
@media screen and (max-width: 320px){
    footer .part2 ul li a{font-size: 14px;}
    footer .lastStrip .part3 ul li a{width: 30px; height: 30px;}
    footer .lastStrip .part3 ul li a svg{font-size: 18px;}
    .phNo h3{ font-size:20px !important; line-height: 25px !important;}
    .phNo p{ font-size: 12px !important;}
}
@media screen and (max-width: 992px){
    footer .part2 ul li a{font-size: 16px;}
    footer .lastStrip .part3 ul li a{width: 35px; height: 35px;}
    footer .lastStrip .part3 ul li a svg{font-size: 20px;}
    .phNo h3{ font-size:25px !important; line-height: 30px !important;}
    .phNo p{ font-size: 14px !important;}
}