
.homeSlider{padding: 0px 0px;}
.home_slider_Main{border-radius: 25px; overflow:hidden; position: relative;}

.slick-prev:before{content: "\f104" !important; font-family: FontAwesome !important; font-size: 30px !important; color: #000 !important;}
.slick-next:before{content: "\f105" !important; font-family: FontAwesome !important; font-size: 30px !important; color: #000 !important;}

.slick-arrow:hover{background: #cfb148 !important;}
.slick-arrow:hover::before{color: #fff !important;}

.home_slider_Main .slick-dots{position: absolute; bottom: 30px !important;}
.home_slider_Main .slick-dots button::before{width: 10px !important; height: 10px !important; font-size: 18px !important;}
.slick-active button::before{color: #cfb148 !important;}

.home_slider_Main .item{position: relative; height: 800px;}
.home_slider_Main .item .info{position: absolute; top: 15%; left: 7%; z-index: 100;margin-top: 100px;}
.home_slider_Main .item .info h2{font-size: 90px; font-weight: bolder; color: rgb(255, 255, 255);}

.home_slider_Main .item .info p{font-size: 35px; font-weight: 500; color: #ffffff;margin-right: 14px;}
.image-container {
    position: relative;
  }
  
  .image-container img {
    display: block;
    width: 100%;
  }
  
  .image-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity here */
    pointer-events: none;
  }
