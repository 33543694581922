.productThumb{width: 100%; height: 720px;  border: 1px solid rgba(0,0,0,0.1); overflow: hidden; border-radius: 15px; padding:25px; transition: all 0.3s ease-in-out; position: relative;}

.productThumb .badge{position: absolute; top: 0px; left: 0px; display: inline-block;
background: red; z-index: 10; color: #fff; padding: 10px 25px; font-size: 16px;
border-bottom-right-radius: 50px; border-top-left-radius: 5px; text-transform: capitalize;}

.productThumb .badge.hot{background: #f74b81 !important;}
.productThumb .badge.sale{background: #67bcee !important;}
.productThumb .badge.new{background: #cfb148 !important;}
.productThumb .badge.best{background: #f59758 !important;}

.productThumb:hover{box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);}

.productThumb .imgWrapper{width: 100%; overflow: hidden; position: relative;}
.productThumb .imgWrapper .wrapper{height: 400px; overflow: hidden;}
.productThumb .imgWrapper img{ transition: all 0.3s ease-in-out;  }

.productThumb .imgWrapper .overlay{position: absolute; top:0px; left: 0px; width: 100%; height: 100%;  padding: 25px; display: flex; align-items: center; justify-content: center; opacity: 0; transform: scale(0.8); }

.productThumb:hover .imgWrapper .overlay{opacity: 1; transform: scale(1); }

.productThumb .imgWrapper .overlay ul{background: #fff; width: 140px; height: auto; border-radius: 10px; border: 1px solid #cfb148; }
.productThumb .imgWrapper .overlay ul {margin-right: 0px !important;}
.productThumb .imgWrapper .overlay ul  a{display: block; padding: 10px; border-right:  1px solid #cfb148;}
.productThumb .imgWrapper .overlay ul  a svg{color: #cfb148 !important;}
.productThumb .imgWrapper .overlay ul   a:hover svg{color: #000 !important; opacity: 0.7;}
 

.productThumb:hover .imgWrapper img{transform: scale(1.1);}

.productThumb .catName{color: #000; opacity: 0.8; font-size: 14px;}
.productThumb .title{margin: 10px 0px;}
.productThumb .title a{color: #000; font-weight: 600; font-size: 22px; text-decoration: none; line-height: 22px;}
.productThumb .brand{color: rgba(0,0,0,0.6);}
.productThumb .brand a{text-decoration: none;}

.productThumb button{font-size: 20px !important; color: #fff !important; padding: 12px 25px !important; background: #cfb148 !important; text-transform: capitalize !important;}
/* .productThumb button:hover{background: #b8b5a3 !important;
     color: #fff !important;} */
@media screen and (max-width: 768px){
    .productThumb{height: auto;}
    .productThumb .imgWrapper{height: 300px;}
    .productThumb .imgWrapper img{height: 300px;}
    .productThumb .imgWrapper .overlay{padding: 15px;}
    .productThumb .imgWrapper .overlay ul{width: 100px;}
    .productThumb .imgWrapper .overlay ul a{padding: 5px;}
    .productThumb .title{margin: 5px 0px;}
    .productThumb .title a{font-size: 18px;}
    .productThumb .brand{font-size: 14px;}
    .productThumb button{font-size: 16px !important; padding: 10px 20px !important;}
     
}
@media screen and (max-width: 576px){
    .productThumb .badge{padding: 10px 15px; font-size: 14px;}
    .productThumb .imgWrapper img{height: 300px;}
    .productThumb .title a{font-size: 16px;}
    .productThumb .brand{font-size: 12px;}
    .productThumb button{font-size: 14px !important; padding: 8px 15px !important;}
}
@media screen and (max-width: 480px){
    .productThumb .badge{padding: 10px 15px; font-size: 14px;}
    .productThumb .imgWrapper img{height: 300px;}
    .productThumb .title a{font-size: 16px;}
    .productThumb .brand{font-size: 12px;}
    .productThumb button{font-size: 14px !important; padding: 8px 15px !important;}
}
@media screen and (max-width: 320px){
    .productThumb .badge{padding: 10px 15px; font-size: 14px;}
    .productThumb .title a{font-size: 16px;}
    .productThumb .imgWrapper img{height: 250px;}
    .productThumb .brand{font-size: 12px;}
    .productThumb button{font-size: 14px !important; padding: 8px 15px !important;}
}
