.homeProducts{ padding: 25px 0px;}

.filterTab li{margin-left: 20px; position: relative;}
.filterTab button{margin-left: 20px; position: relative;}
.filterTab li a{color: #000; text-decoration: none; font-size: 20px; transition: all 0.3s ease-in-out; position: relative; font-weight: 600;}
.filterTab li:hover a, .filterTab li a.act{color: #cfb148 !important; transform: translateY(-10px); }

.homeProducts .productRow{ display: flex; padding: 30px 0px; margin: 0px -6px; flex-wrap: wrap;}
.homeProducts .productRow.loading{opacity: 0.5; filter: blur(5px);}

.homeProducts .productRow .item{width: 25%;  padding: 15px 15px;}
.homeProductWrapper .productRow .item{width: 20%;}

.homeProductsRow2 .prodSlider .item{padding:15px 5px;  padding-right: 25px  !important;}


.topProductsSection{padding: 35px 0px;}


.newsLetterSection{width: 100%; height: 600px;  }
.newsLetterSection .box{background: url(../../assets/images/ddd.jpg); width: 100%; height: 550px; padding: 60px 80px; border-radius: 30px; overflow: hidden; }

.newsLetterSection .box .info{width: 55%;}
.newsLetterSection .box .info h2{font-size: 60px; font-weight: 600; line-height: 70px;   }
.newsLetterSection .box .info p{font-size: 29px; font-weight: 600; }
 .newsLetterSection .box  .newsLetterBanner{position: relative !important; left: 0px !important; bottom: inherit !important;}
 @media screen and (max-width: 320px) {
    .newsLetterSection .box{padding: 60px 20px;}
    .newsLetterSection .box .info{width: 100%;}
    .newsLetterSection .box .info h2{font-size: 20px; line-height: 50px;}
    .newsLetterSection .box .info p{font-size: 20px;}
    .newsLetterSection .box  .newsLetterBanner{position: absolute !important; left: 50%; bottom: 0px !important; transform: translateX(-50%);}
 }
 @media screen and (max-width: 480px) {
    .newsLetterSection .box{padding: 60px 20px;}
    .newsLetterSection .box .info{width: 100%;}
    .newsLetterSection .box .info h2{font-size: 40px; line-height: 50px;}
    .newsLetterSection .box .info p{font-size: 20px;}
    .newsLetterSection .box  .newsLetterBanner{position: absolute !important; left: 50%; bottom: 0px !important; transform: translateX(-50%);}
 }
 @media screen and (max-width: 576px) {
    .newsLetterSection .box{padding: 60px 20px;}
    .newsLetterSection .box .info{width: 100%;}
    .newsLetterSection .box .info h2{font-size: 40px; line-height: 50px;}
    .newsLetterSection .box .info p{font-size: 20px;}
    .newsLetterSection .box  .newsLetterBanner{position: absolute !important; left: 50%; bottom: 0px !important; transform: translateX(-50%);}
 }
 @media screen and (max-width: 768px) {
    .newsLetterSection .box{padding: 60px 20px;}
    .newsLetterSection .box .info{width: 100%;}
    .newsLetterSection .box .info h2{font-size: 40px; line-height: 50px;}
    .newsLetterSection .box .info p{font-size: 20px;}
    .newsLetterSection .box  .newsLetterBanner{position: absolute !important; left: 50%; bottom: 0px !important; transform: translateX(-50%);}
    .topProductsSection .row .col{width:280px !important; vertical-align: top !important; display: inline-block !important; float: none !important;height: 850px;}
   .topProductsSection .row .col img{width: 90%;height: 90%;}
 }
 @media screen and (max-width: 992px) {
    .newsLetterSection .box{padding: 60px 20px;}
    .newsLetterSection .box .info{width: 100%;}
    .newsLetterSection .box .info h2{font-size: 30px !important; line-height: 50px;}
    .newsLetterSection .box .info p{font-size: 20px;}
    .newsLetterSection .box  .newsLetterBanner{position: absolute !important; left: 50%; bottom: 0px !important; transform: translateX(-50%);
   }
   .topProductsSection .row .col{width:290px !important; vertical-align: top !important; display: inline-block !important; float: none !important;height: 850px;}
   .topProductsSection .row .col img{width: 90%;height: 90%;}
 }

