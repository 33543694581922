.loginWrapper{padding: 75px 0px; background: #f1f1f1;}
.loginWrapper .card{width: 650px; margin: auto; padding: 40px; border: 0px !important; overflow: hidden;}
.loginWrapper .card h3{font-weight: 500; font-size: 35px;}
.loginWrapper .card input{height: 40px !important; font-size: 18px !important; color: #000 !important;}
.loginWrapper .card fieldset{border-radius: 10px !important;}
.loginWrapper .card  .MuiFormLabel-root{line-height: 43px !important; color: #000 !important; font-size: 18px !important;}

.loginWrapper .card .icon{position: absolute; top: 10px; right: 20px; z-index: 100; min-width:50px !important; height:50px !important; width:50px !important; color: #ccc !important; border-radius: 100% !important;}
.loginWrapper .card .icon svg{color: #000 !important; opacity: 0.8;}

.loginWrapper .card button{padding: 15px 25px !important;}
.signInOr button{padding: 15px 25px !important; color: #000 !important; font-size: 18px !important;}
.signInOr button img{width: 40px; margin-right: 15px;}

.formLoader{position: absolute !important; background: rgba(255,255,255,0.5) !important;}