body {
    font-family: Arial;
    font-size: 17px;
    padding: 0px;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
  }
  
  .col-25 {
    flex: 25%;
  }
  
  .col-50 {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
  }
  
  .col-75 {
    flex: 40%;
  }
  
  .col-25,
  .col-50,
  .col-75 {
    font-weight: 600; font-size: 18px;
    padding: 25px 19px;
  }
  .container {
    padding: 5px 20px 15px 20px;
    width: 100%;
    border: 1px solid #cfb148;
    border-radius: 3px;
  }
  
 .col-50 input[type=text] {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #cfb148;
    border-radius: 3px;
  }
  
  label {
    margin-bottom: 10px;
    display: block;
  }
  
  .icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
  }
  .submit {
    background-color: #cfb148;
    color: white;
    padding: 12px;
    margin: 10px auto; /* Adjusted margin for centering horizontally */
    border: none;
    width: 40%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
    display: block; /* Ensures the button takes up the specified width */
}

  
  .submit:hover {
    background-color: #b8b5a3;
    color: #ffffff;
  }
  
  a {
    color: #cfb148;
  }
  
  hr {
    border: 1px solid lightgrey;
  }
  
  span.price {
    float: right;
    color: grey;
  }
  
  @media (max-width: 800px) {
    .row {
      flex-direction: column-reverse;
    }
    .col-25 {
      margin-bottom: 20px;
    }
  }