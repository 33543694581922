.dialog-transition {
    position: fixed;
    z-index: 50;
  }
  
  .dialog-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: rgb(90, 90, 90);
    }
  
  .dialog-panel {
    max-width: 32rem;
    background-color: #fff;
    padding: 3rem;
    border-radius: 0.75rem;
  }
  
  .dialog-title {
    font-weight: bold;
  }
  
  .dialog-description {
    color: #333;
  }
  
  .dialog-text {
    color: #666;
  }
  
  .dialog-button {
    border: none;
    background-color: #d3c614;
    color: #fff;
    padding: 0.5rem 1rem;
    
    border-radius: 1rem;
    cursor: pointer;
  }
  
  .dialog-button:hover {
    background-color: #8a8771;
  }
  