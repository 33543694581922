.site-header {
    text-align: center;
}

.site-header img {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.main-content {
    padding: 20px;
}

.main-content__body {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
}

@media (min-width: 600px) {
    .main-content__body {
        font-size: 1.2rem;
    }
}
